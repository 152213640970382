.profile {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-evenly;
	margin: auto;
	width: 90rem;
	padding: 1.5rem 0;
}
.main-profile-menu {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 1.5rem 0;
	width: 15%;
}
.fixed-menu {
	position: absolute;
	display: flex;
	flex-direction: column;
	position: fixed;
}
.profile-content-container {
	width: 75%;
	display: flex;
	flex-direction: column;
}
.profile-menu-item {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 3rem;
	width: 100%;
	cursor: pointer;
	color: black;
	position: relative;
	transition: 0.5s;
	margin: 1rem 0;
	padding: 0 2.5rem;
}
.profile-menu-item::backdrop {
	background-color: darkorange;
}
.profile-menu-item::before {
	content: "";
	background-color: gray;
	height: 100%;
	width: 5%;
	border-radius: 10px;
	right: 0;
	transition: 0.5s;
	position: absolute;
	z-index: 4;
}
.profile-menu-item:hover {
	color: var(--unilab-white);
}
.profile-menu-item:hover::before {
	width: 100% !important;
	background-color: var(--unilab-orange);
}
.item-text {
	z-index: 5;
}
.active-menu-item::before {
	background-color: var(--unilab-orange);
}
.profile-banner {
	width: 100%;
	background-image: url("../../Assets/img/vienna.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 10px 10px 0 0;
	padding: 3rem;
}
.profile-banner-pfp {
	border-radius: 50%;
	height: 10rem;
	width: 10rem;
	object-fit: cover;
	object-position: center;
	background-color: var(--unilab-white);
	padding: 3px;
	border: 1px solid var(--gray-hover);
}
.profile-basic-info {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1rem 2rem;
}
.basic-info-toggler {
	height: 3rem;
	width: 3rem;
	border-radius: 50%;
	padding: 1%;
	object-fit: cover;
	object-position: center;
	position: absolute;
	right: 1rem;
	top: 1rem;
	cursor: pointer;
	transition: 0.1s;
}
.basic-info-toggler:hover {
	background-color: var(--gray-hover);
}
.website-link {
	color: #0084ff;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
}
.post-comment-container {
	width: 100%;
	padding: 1rem;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 1rem 0;
}
.post-comment-content {
	width: calc(100% - 4rem - 15%);
	max-height: 4rem;
	padding: 0 1rem;
}
.post-comment-btn-holder {
	display: flex;
	width: 15%;
	justify-content: space-between;
	align-self: center;
}
.post-comment-btn {
	padding: 0.5rem 0 !important;
	width: 49%;
	text-align: center;
	margin: 0.5rem 0;
}
.post-comment-img {
	height: 4rem;
	width: 4rem;
	object-fit: cover;
	object-position: center;
	border-radius: 10px;
}
.post-comment-noimg {
	height: 4rem;
	border-radius: 10px;
}

.main-content-management {
	flex-direction: row !important;
	height: 100vh !important;
	width: 100% !important;
	justify-content: center !important;
	align-items: stretch !important;
	position: fixed;
	top: 0;
	padding: 3rem;
	overflow: hidden;
}
.admin-menu {
	/*width: 15rem;*/
	display: flex;
	flex-direction: row;
}
.admin-menu-item {
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 2rem;
	cursor: pointer;
	font-size: 1rem;
	font-weight: bold;
	color: var(--unilab-black);
	text-align: center;
}
.admin-menu-item:hover {
	background-color: var(--gray-hover);
}
.admin-content {
	display: flex;
	justify-content: center;
}
.content-vertical {
	flex-direction: column;
}
.content-horizontal {
	flex-direction: row;
}
.add-new-field {
	width: 35rem;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	scrollbar-color: transparent transparent;
}
.add-new-field:hover {
	scrollbar-color: lightgray rgba(0, 0, 0, 0);
}
.add-new-field::-webkit-scrollbar {
	opacity: 0 !important;
}
.add-new-field:hover:-webkit-scrollbar {
	opacity: 1 !important;
}
.add-new-input-field {
	padding: 1rem;
	border-radius: 10px;
	border: 1px solid transparent;
	display: flex;
	align-items: center;
	margin: 0 1rem 1rem 1rem;
}
.add-new-input-field:focus-within {
	border: 1px solid var(--unilab-orange);
}
.add-new-search-icon {
	margin-right: 1rem;
}
.add-new-input {
	border: none;
	outline: none;
	padding: 0;
	flex-grow: 1;
}

.basic-list-item {
	display: flex;
	align-items: center;
	padding: 1rem;
	border-radius: 10px;
	margin: 1rem;
	position: relative;
}
.basic-list-item:hover .basic-list-item-btn {
	display: flex;
}
.basic-list-item-icon {
	height: 2.5rem;
	width: 2.5rem;
	object-fit: cover;
	object-position: center;
	border-radius: 50%;
	border: 1px solid var(--gray-hover);
	margin-right: 1rem;
}
.basic-list-item-btn {
	margin-left: auto;
	color: var(--unilab-orange);
	font-weight: bold;
	display: none;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(0, -50%);
	background-color: var(--unilab-white);
	height: 100%;
	padding: 1rem;
	border: 0.1px solid transparent;
	border-radius: 10px;
}
.basic-list-item-btn:focus {
	border: 0.1px solid var(--unilab-orange);
}
.active-item {
	color: var(--unilab-orange);
}
