@media only screen and (min-width: 1030px) {
	.language-menu {
		width: 20rem;
		justify-content: flex-end;
	}

	.menu-lang-enter {
		position: absolute;
		transform: translateX(110%);
	}
	.menu-lang-enter-active {
		transform: translateX(0%);
		transition: all 500ms ease;
	}
	.menu-lang-exit {
		position: absolute;
	}
	.menu-lang-exit-active {
		transform: translateX(110%);
		transition: all 500ms ease;
	}
}
@media only screen and (max-width: 1030px) {
	.language-menu {
		width: 100%;
		justify-content: flex-start;
		overflow-y: scroll;
	}
	.language-menu-closed {
		left: 100%;
	}
	.language-menu-opened {
		opacity: 1 !important;
		left: 0;
		overflow-y: scroll;
	}
}
.language-menu {
	right: 0;
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 9501;
	height: 100vh;
	top: 0;
	background-color: var(--unilab-white);
	transition: 0.4s linear;
}
.select-lang {
	margin: 3rem 0;
}
#language-picture {
	height: 25vh;
	width: 25vh;
	border-radius: 50%;
	object-fit: cover;
}
.language-close-button {
	right: unset !important;
	left: 2% !important;
}
.language-links {
	background-color: transparent;
	outline: none;
	border: none;
	color: black;
	display: flex;
	width: 100%;
	padding: 2rem 4rem;
	transition: 0.1s;
	align-items: center;
	cursor: pointer;
}
.language-links img {
	height: 2.5rem;
	width: 2.5rem;
	margin-right: 1rem;
	border-radius: 50%;
	object-fit: cover;
	object-position: center center;
	border: 1px solid var(--gray-hover);
}
.language-links:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
.lang-menu-btn-holder {
	transition: all 0.4s linear;
}
