.return-btn {
	background-color: transparent;
	border: none;
	outline: none;
	padding: 1rem;
	align-self: flex-start;
	font-weight: bold;
	cursor: pointer;
	color: black;
}
.job-details {
	/* background-color: var(--unilab-gray); */
	display: flex;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	border-radius: 10px;
}
.job-details-left,
.job-details-right {
	width: 50%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	padding: 1rem;
	margin: 1rem;
}
.job-company-img {
	height: 5rem;
	object-fit: cover;
	object-position: center;
	margin-bottom: 1rem;
}
.job-details-left {
	border-right: 1px solid lightgray;
}
hr {
	color: lightgray;
}
.job-details-right {
	background-color: var(--unilab-gray);
	border-radius: 10px;
}
.job-mini-btns-holder {
	display: flex;
	width: 100%;
	justify-content: space-evenly;
	margin-bottom: 1rem;
}
.job-mini-btn {
	border-radius: 10px;
	padding: 0.1rem;
	width: 20%;
	text-align: center;
	cursor: pointer;
	border: 1px solid transparent;
	transition: 0.1s;
}
.job-mini-btn:hover {
	border: 1px solid black;
}
.job-info-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
}
.job-info-subcontainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 40%;
}
.job-info-text {
	margin-bottom: 1rem;
	text-align: center;
}
.job-info-longer-text {
	margin-bottom: 1rem;
	padding: 1rem;
}
.apply-btn {
	position: fixed;
	top: 50%;
	right: 2rem;
	transform: translate(0, -50%);
	width: 7.5%;
}
.apply-btn:active {
	transform: translate(0, -50%);
}
.file-upload-2 {
	align-self: flex-start;
}
