.uni-form-btn {
	position: absolute;
	top: 1rem;
	right: 1rem;
}
.uni-form-wrapper {
	position: fixed;
	height: 100vh;
	width: 50rem;
	top: 0;
	left: 50%;
	background-color: var(--unilab-white);
	z-index: 10000000;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	transform: translate(-50%, 0);
}
.uni-form {
	width: 100%;
	padding: 0 2.5rem;
	margin: 2.5rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.uni-form-title {
	margin: 0;
	width: 100%;
	text-align: center;
	font-weight: normal;
	margin-bottom: 2.5rem;
}
.uni-create-btn {
	width: 100%;
	text-align: center;
	margin-top: 2.5rem;
}
.btn-wrap {
	position: absolute;
}

.uni-form-transition-enter {
	opacity: 0;
	transform: scale(0.9);
}
.uni-form-transition-enter-active {
	opacity: 1;
	transform: translate(-50%, 0);
	transition: opacity 300ms, transform 300ms;
}
.uni-form-transition-exit {
	opacity: 1;
}
.uni-form-transition-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
