@media only screen and (min-width: 1030px) {
	.login-form {
		display: flex;
		z-index: 10500 !important;
		position: fixed;
		align-items: center;
		flex-direction: column;
		--form-width: min(30vw, 500px);
		width: var(--form-width);
		background-color: var(--unilab-white);
		overflow-y: auto;
		border-radius: 10px;
		padding: 3rem;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
@media only screen and (max-width: 1030px) {
	.login-form {
		display: flex;
		z-index: 10500 !important;
		position: fixed;
		align-items: center;
		flex-direction: column;
		/* --form-width: min(30vw, 500px);
		width: var(--form-width); */
		background-color: var(--unilab-white);
		overflow-y: auto;
		border-radius: 10px;
		padding: 3rem;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
	}
}
.menu-login-enter {
	opacity: 0;
	transform: scale(0.9);
}
.menu-login-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
}
.menu-login-exit {
	opacity: 1;
}
.menu-login-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.signin-buttons {
	display: flex;
	justify-content: space-between;
}
.sign-in {
	margin-bottom: 3rem;
}
.forgot-pass {
	margin-top: 3rem;
}
.login-btn {
	margin-top: 3rem;
}
