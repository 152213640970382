@media only screen and (min-width: 1030px) {
	.create-post {
		width: 40rem;
		margin: 3rem 0;
		padding: 2rem;
		background-color: var(--unilab-gray);
		border-radius: 10px;
	}
	.action-btn {
		display: flex;
		align-items: center;
		padding: 0 2rem;
		width: 80%;
		height: 60px;
		border-radius: 50px;
		cursor: pointer;
		transition: 0.2s;
		background-color: var(--unilab-white);
		margin: 0;
	}
	.post-pfp {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		margin-right: 2rem;
		background-size: cover;
		background-position: center center;
		border: 1px solid var(--gray-hover);
	}
}
@media only screen and (max-width: 1030px) {
	.create-post {
		width: 100%;
		margin-bottom: 2rem;
		padding: 1.5rem;
		background-color: var(--unilab-gray);
	}
	.action-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 1rem;
		height: 3rem;
		border-radius: 25px;
		cursor: pointer;
		transition: 0.2s;
		background-color: var(--unilab-white);
		margin: 0;
		flex-grow: 1;
	}
	.post-pfp {
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		margin-right: 1rem;
		background-size: cover;
		background-position: center center;
		border: 1px solid var(--gray-hover);
	}
}
.action-btn:hover {
	background-color: unset;
}
