.fab {
	width: 4rem;
	height: 4rem;
	background-color: var(--unilab-orange);
	border-radius: 50%;
	transition: all 0.1s ease-in-out;

	font-size: 1rem;
	color: white;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 10;
	cursor: pointer;
	right: 1rem;
	bottom: 1rem;
}

.fab:hover {
	background-color: var(--unilab-orange-hover);
	transform: scale(1.05);
}
