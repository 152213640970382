.settings {
	width: 75rem;
	display: flex;
	margin: auto;
	justify-content: space-evenly;
	padding: 1.5rem 0;
}
.settings-content-container {
	width: 60%;
	display: flex;
	flex-direction: column;
	align-self: flex-end;
}
.settings-content {
	border-radius: 10px;
	padding: 3rem 5rem;
	margin: 1.5rem 0;
}
.settings-content-title {
	margin: 0;
}
.settings-menu {
	width: 20%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 1.5rem 0;
	position: sticky;
	top: 0;
}
.settings-menu-nav {
	position: fixed;
}
.settings-menu-item {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	transition: 0.3s;
	border-bottom: 1px solid transparent;
	border-radius: 10px;
	margin: 1rem 0;
	width: 100%;
	cursor: pointer;
}
.settings-menu-item:hover {
	border-bottom: 1px solid var(--unilab-orange);
}
.settings-menu-item span {
	width: calc(100% - 3rem);
}
.settings-menu-img {
	height: 2rem;
	margin-right: 1rem;
}
.active-settings-item {
	border-bottom: 1px solid var(--unilab-orange);
}
.save-btn {
	margin-top: 3rem;
}
.passwords-container {
	margin-top: 3rem;
}
