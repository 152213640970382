@media only screen and (min-width: 1030px) {
	.profile-menu {
		width: 20rem;
	}
	.profile-user-data {
		margin-top: 3rem;
	}
	.menu-primary-enter {
		position: absolute;
		transform: translateX(110%);
	}
	.menu-primary-enter-active {
		transform: translateX(0%);
		transition: all 500ms ease;
	}
	.menu-primary-exit {
		position: absolute;
	}
	.menu-primary-exit-active {
		transform: translateX(110%);
		transition: all 500ms ease;
	}
}
@media only screen and (max-width: 1030px) {
	.profile-menu {
		width: 100%;
		overflow-y: scroll;
	}
	.profile-menu-closed {
		right: -100%;
		padding-top: 0rem;
	}
	.profile-menu-opened {
		opacity: 1 !important;
		right: 0;
		padding-top: 3rem;
	}
	.profile-user-data {
		margin-top: 5rem;
	}
}
.profile-menu {
	right: 0;
	display: flex;
	position: fixed;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	z-index: 9501;
	height: 100vh;
	top: 0;
	background-color: var(--unilab-white);
}
.profile-picture {
	height: 7rem;
	width: 7rem;
	border-radius: 50%;
	object-fit: cover;
	margin-bottom: 2rem;
}
.settings-picture,
.logout-picture,
.prof-picture,
.login-picture,
.signup-picture,
.companies-picture,
.universities-picture,
.management-picture {
	height: 3vh;
	width: 3vh;
	margin: 0 3%;
	background-size: contain;
	background-position: center center;
}
.prof-picture {
	background-image: url("../../Assets/img/profile.png");
}
.settings-picture {
	background-image: url("../../Assets/img/settings2.png");
}
.logout-picture {
	background-image: url("../../Assets/img/logout2.png");
	transform: rotate(180deg);
}
.login-picture {
	background-image: url("../../Assets/img/logout2.png");
}
.signup-picture {
	background-image: url("../../Assets/img/sign_up.png");
}
.companies-picture {
	background-image: url("../../Assets/img/top-nav/companies.png");
}
.universities-picture {
	background-image: url("../../Assets/img/defaults/university.jpg");
}
.management-picture {
	background-image: url("../../Assets/img/defaults/management.png");
}
.settings-button {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 3% 5%;
	background-color: rgba(0, 0, 0, 0);
	color: var(--unilab-orange);
	transition: 0.1s;
	width: 100%;
	outline: none;
	border: none;
}
.settings-button:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
.settings-button h3 {
	text-align: start;
}
.profile-close-button {
	right: unset !important;
	left: 2% !important;
}
.profile-names {
	font-size: 1.25em;
	text-align: center;
	width: 80%;
	font-weight: bold;
	margin-bottom: 2rem;
}
p {
	text-align: start;
}
.verbose {
	margin-bottom: 2rem;
	text-align: center;
}
