.collapsible-menu-btn {
	width: 100%;
	padding: 1rem;
	transition: 0.2s;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	border-bottom: 1px solid var(--gray-hover);
}
.collapsible-menu-content {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	width: 100%;
	padding: 1rem;
}
.collapsible-menu-content div {
	margin: 1rem 0;
	width: 49%;
	font-weight: bold;
	font-size: 1.15rem;
}
