.applicants-container {
	display: flex;
	z-index: 10500 !important;
	position: fixed;
	align-items: center;
	flex-direction: column;
	width: 100%;
	background-color: var(--unilab-white);
	overflow: hidden;
	padding: 2.5rem 0;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	height: 80vh;
	margin: auto;
}
.applicants-list {
	display: flex;
	justify-content: center;
	flex-flow: row wrap;
	gap: 1rem;
	padding: 1rem;
}
