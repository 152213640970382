.close-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	right: 0;
	top: 0;
	cursor: pointer;
	outline: none;
	border: none;
	color: var(--unilab-orange);
	height: 2.5rem;
	width: 2.5rem;
	padding: 0;
	background-color: rgba(0, 0, 0, 0);
	position: relative;
	transition: 0.1s;
}
.close-btn::before,
.close-btn::after {
	content: "";
	position: absolute;
	display: block;
	height: 3%;
	width: 50%;
	background-color: var(--unilab-orange);
	transition: all 0.25s ease-out;
	border-radius: 50px;
}
.close-btn:hover {
	background-color: var(--gray-hover);
}
.close-btn::before {
	transform: rotate(-45deg);
}
.close-btn::after {
	transform: rotate(-135deg);
}
