.profile-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	margin: 1.5rem 0;
}
.profile-content-upper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-content: center;
	margin: 1rem 0;
}
