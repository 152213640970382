.loader-orange {
	--loader-color: var(--unilab-orange);
	stroke: var(--unilab-orange);
}
.loader-black {
	--loader-color: var(--unilab-black);
	stroke: var(--unilab-black);
}
.loader-gray {
	--loader-color: gray;
	stroke: gray;
}
.loader-white {
	--loader-color: var(--unilab-white);
	stroke: var(--unilab-white);
}
.size-1 {
	width: 1rem;
	height: 1rem;
}
.size-2 {
	width: 2rem;
	height: 2rem;
}
.size-3 {
	width: 3rem;
	height: 3rem;
}
.size-4 {
	width: 4rem;
	height: 4rem;
}
.size-5 {
	width: 5rem;
	height: 5rem;
}
.loader-box {
	z-index: 100;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
}
.loader-box .loader {
	position: relative;
	margin: 0px auto;
}
.loader-box .loader:before {
	content: "";
	display: block;
	padding-top: 100%;
}
.circular-loader {
	-webkit-animation: rotate 2s linear infinite;
	animation: rotate 2s linear infinite;
	height: 100%;
	-webkit-transform-origin: center center;
	-ms-transform-origin: center center;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
}
.loader-path {
	stroke-dasharray: 150, 200;
	stroke-dashoffset: -10;
	-webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
	animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
	stroke-linecap: round;
}
@-webkit-keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-webkit-keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124;
	}
}
@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124;
	}
}
@-webkit-keyframes color {
	0% {
		stroke: var(--loader-color);
	}
	40% {
		stroke: var(--loader-color);
	}
	66% {
		stroke: var(--loader-color);
	}
	80%,
	90% {
		stroke: var(--loader-color);
	}
}
@keyframes color {
	0% {
		stroke: var(--loader-color);
	}
	40% {
		stroke: var(--loader-color);
	}
	66% {
		stroke: var(--loader-color);
	}
	80%,
	90% {
		stroke: var(--loader-color);
	}
}
