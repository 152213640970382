@media only screen and (min-width: 1030px) {
	.sorting-menu {
		width: 40rem;
		background-color: var(--unilab-gray);
		padding: 2rem;
		margin-bottom: 3rem;
		border-radius: 10px;
	}
	.sorting-item {
		background-color: var(--unilab-white);
		width: 15%;
		padding: 2%;
		text-align: center;
		margin: 0 1%;
		border-radius: 50px;
		transition: 0.2s;
		cursor: pointer;
	}
}
@media only screen and (max-width: 1030px) {
	.sorting-menu {
		width: 100%;
		background-color: var(--unilab-gray);
		padding: 1.5rem;
		margin-bottom: 2rem;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}
	.sorting-item {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--unilab-white);
		width: 3rem;
		height: 3rem;
		text-align: center;
		border-radius: 50px;
		transition: 0.2s;
		cursor: pointer;
	}
}
.sorting-item:hover {
	background-color: var(--unilab-gray);
	color: black;
}
