.search-wrapper {
	height: var(--topnav-height);
	width: 100%;
	background-color: var(--unilab-white);
	top: 0;
	z-index: 10000;
	display: flex;
}
.search-menu-opened {
	display: flex !important;
	flex-direction: column;
	-webkit-animation: slide 0.5s forwards;
	-webkit-animation-delay: 0s;
	animation: slide 1s forwards;
	animation-delay: 0s;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10000;
}
@-webkit-keyframes slide {
	100% {
		padding-left: 0;
	}
}
@keyframes slide {
	100% {
		padding-left: 0;
	}
}
.search-menu-closed {
	display: none !important;
}
#search-icon {
	cursor: pointer;
	height: 2.5rem;
	width: 2.5rem;
}
.search-field {
	width: 50%;
	height: 100%;
	outline: none;
	border: none;
	margin: 0 1rem;
	z-index: 9001;
	padding: 0;
}
.search-cancel {
	height: 2.5rem;
	width: 2.5rem;
	cursor: pointer;
	position: relative !important;
}

.menu-search-enter {
	position: absolute;
	transform: translateY(-110%);
}
.menu-search-enter-active {
	transform: translateY(0%);
	transition: all 500ms ease;
}
.menu-search-exit {
	position: absolute;
}
.menu-search-exit-active {
	transform: translateY(-110%);
	transition: all 500ms ease;
}
